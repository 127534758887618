.exfbdt {
  width: 100%;
  height: 100vh;
  .container {
    width: 100%;
    padding: 32px;
    height: 100%;
  }
  .Fullscreen {
    display: none;
  }
  .v360-viewport {
    height: calc(100vh - 64px);
    align-items: center;
    touch-action: pan-x; /* Prevent zooming, allow horizontal panning */
    overscroll-behavior: contain; /* Prevents the viewport from scrolling */
    user-select: none;
    canvas {
      object-fit: contain;
    }
  }
  #v360-menu-btns {
    display: none;
  }
  .cloudimage-360 {
    height: 100%;
  }
  .cloudimage-360-inner-box {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .cloudimage-360-icons-container {
    display: none;
  }
  .cloudimage-360-view-360-icon {
    display: none;
  }
}

.modal {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
  &.none {
    display: none;
  }
  &Content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .form {
    max-width: 498px;
    padding: 56px 32px;
    width: 100%;
    text-align: center;
    &.success {
      input {
        background-color: #89c36e;
        border: 1px solid #89c36e;
      }
      button {
        background-color: #89c36e;
      }
    }
    &.error {
      input {
        outline: 4px solid #ff570f;
        border: 1px solid transparent;
        &:focus-visible {
          outline: 4px solid #ff570f;
        }
      }
      button {
        background-color: #ff570f;
      }
    }
    h3 {
      color: #cdbea8;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 20px;
    }
    input {
      width: 100%;
      display: block;
      padding: 20px 40px;
      border-radius: 60px;
      border: 1px solid #e3e3e3;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
      backdrop-filter: blur(10px);
      margin-bottom: 24px;
      color: #333333;
      font-size: 24px;
      font-weight: 400;
      line-height: normal;
      outline: none;
      background-color: #e3e3e3;
      transition: all 0.2s ease-in-out;
      outline: 4px solid transparent;
      &::placeholder {
        color: #e6e4e060;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
      }
      &:focus-visible {
        outline: none;
      }
    }
    button {
      border: none;
      outline: none;
      padding: 12px 12px;
      color: #f5f5f5;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      line-height: normal;
      background-color: #2a2a2a;
      border-radius: 999px;
      max-width: 200px;
      width: 100%;
      cursor: pointer;
      margin: 0 auto;
      transition: all 0.2s ease-in-out;
      &:disabled {
        background-color: #2a2a2a50;
        cursor: not-allowed;
      }
    }
  }
}
