@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --vh: 1vh;
}

@font-face {
  font-family: "Graphik Regular";
  src: url(../src/assets/fonts/Graphik-Regular-Web.woff2);
}

@font-face {
  font-family: "Graphik Medium";
  src: url(../src/assets/fonts/Graphik-Medium-Web.woff2);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Graphik Book", sans-serif !important;
  letter-spacing: 0.1px;
}

a {
  text-decoration: none;
  display: inline-block;
}

body {
  margin: 0;
  // font-family: Arial, sans-serif;
  font-family: "Graphik Regular" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html{
  height: 100vh;
  overflow: hidden;
}