.login {
  width: 100%;
  height: 100vh;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &Box {
    max-width: 484px;
    width: 100%;
    border-radius: 60px;
    border: 1px solid #ffffff;
    padding: 148px 76px 148px;
    background-color: #f5f5f5;
    &.error {
      // border: 2px solid #ff5515;
      input {
        outline: 2px solid #ff5515;
        border-color: #ff5515;
      }
      button {
        background-color: #ff5515;
        color: #ff5515;
      }
    }
    &.success {
      input {
        outline: 1.5px solid #89c36e;
        border-color: #89c36e;
        &:focus-visible {
          outline: 1.5px solid #89c36e;
          border-color: #89c36e;
        }
      }
      button {
        background-color: #89c36e;
        color: #ffffff;
      }
    }
  }
  &Form {
    &Field {
      padding-bottom: 12px;
    }
    &Submit {
      padding-top: 22px;
      text-align: center;
    }
    input {
      width: 100%;
      padding: 20px 40px;
      border-radius: 60px;
      border: 1px solid #6e6e6e;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
      backdrop-filter: blur(10px);
      background-color: transparent;
      font-size: 28px;
      font-weight: 400;
      line-height: normal;
      outline: 2px solid transparent;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 0 0px 1000px #f5f5f5 inset;
      &:focus-visible {
        outline: none;
      }
      &::placeholder {
        color: #bfbfbf;
        font-size: 28px;
        font-weight: 400;
        line-height: normal;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:focus-visible,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0px 1000px #f5f5f5 inset;
        -webkit-box-shadow: 0 0 0px 1000px #f5f5f5 inset;
        font-size: 28px !important;
        font-weight: 400;
        line-height: normal;
      }
    }

    button {
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      line-height: normal;
      padding: 12px 10px;
      border-radius: 999px;
      max-width: 200px;
      width: 100%;
      cursor: pointer;
      outline: none;
      border: none;
      background: #21272a;
      color: #ffffff;
      transition: all 0.2s ease-in-out;
      &:disabled {
        background: #cdcdcd;
        color: #bababa;
        cursor: not-allowed;
      }
    }
  }
  &FormHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 200px;
    width: 100%;
    gap: 12px;
    margin: 0 auto;
    padding-bottom: 40px;
    svg {
      width: 60px;
      height: 16px;
    }
    p {
      color: #adadad;
      font-size: 20px;
      font-weight: 450;
      line-height: normal;
    }
  }
  .unsopported {
    display: none;
  }
  @media (max-width: 1000px) {
    .unsopported {
      display: flex;
      background-color: #f3f4f4;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100vh;
      p {
        color: #ff5515;
        font-size: 17px;
        line-height: 180%;
        text-align: center;
      }
    }
    .container {
      display: none;
    }
  }
}
