.unreal {
  h4 {
    position: absolute;
    width: 1px;
    height: 100vh;
    background-color: red;
    left: 50%;
    top: 0;
  }
  &Content {
    display: flex;
    height: 100vh;
    background-color: #f3f4f4;
  }
  &Sidebar {
    padding: 0.391vw 0.391vw 2.031vw;
    // min-width: 15.625vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #e3e3e3;
    border-radius: 14px;
    margin: 0.625vw 0 0.625vw 0.625vw;
    &Disable {
    }
    &Reset {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      cursor: pointer;
      border-radius: 10px;
      transition: all 0.1s ease-in-out;
      &:hover {
        background-color: #ff5515;
        span {
          color: #ffffff;
        }
      }
      span {
        font-size: 0.8vw;
        font-weight: 400;
        line-height: normal;
        color: #929292;
        display: block;
        color: #434343;
      }
    }
  }
  &Logo {
    text-align: center;
    padding-bottom: 0.6vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.25vw;
    svg {
      width: 4.688vw;
      height: 1.25vw;
    }
    p {
      color: #989898;
      font-size: 0.859vw;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.22px;
      padding-top: 0.313vw;
    }
  }
  &List {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.391vw;
  }
  &Accordion {
    pointer-events: all;
  }
  &Qtn {
    padding: 0.781vw 1.563vw;
    cursor: pointer;
    position: relative;
    transition: all 0.1s ease-in-out;
    background-color: #d4d4d4;
    white-space: nowrap;
    h2 {
      color: #434343;
      font-size: 0.8vw;
      font-weight: 400;
      line-height: normal;
      font-family: "Graphik Regular" !important;
      padding-bottom: 0.313vw;
    }
    h3 {
      font-size: 0.8vw;
      font-weight: 400;
      line-height: normal;
      color: #929292;
    }
    span {
      display: block;
      position: absolute;
      right: 1.563vw;
      top: 50%;
      margin-top: -0.5vw;
      img {
        width: 1vw;
        height: 1vw;
        opacity: 0.5;
        transition: all 0.1s ease-in-out;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &Capture {
    display: flex;
    gap: 1.563vw;
    align-items: center;
    padding: 1.172vw 1.563vw;
    h4 {
      font-size: 1.563vw;
      color: #434343;
      font-weight: 400;
      line-height: 1;
    }
  }
  &Opt {
    // height: 0;
    max-width: 100%;
    overflow: hidden;
    margin: 0 0 0 auto;
    background-color: #d4d4d4;
    border-radius: 0 0 6px 6px;
    position: relative;
    padding: 12px 0;
    white-space: nowrap;
    &::before {
      // content: "";
      width: calc(100% - 24px);
      background-color: #434343;
      height: 0.3px;
      top: 0;
      left: 12px;
      position: absolute;
    }
    p {
      padding: 0.469vw 1.563vw;
      text-align: center;
      // border-radius: 6px;
      // background-color: #ebeaea;
      color: #6e6e6e;
      text-align: center;
      font-size: 0.859vw;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      // border: 0.5px solid #b5b5b5;
      &.activeOpt {
        // background-color: #f3f4f480;
        color: #ff5515;
        border: none;
      }
      &:hover {
        // color: #ff570f;
        background-color: #e0e0e0;
        border-radius: 200px;
        // border-color: #4e4e4e;
      }
      &:first-child {
        margin-top: 0.313vw;
      }
      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }
  &Body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  &Asset {
    background-color: #f5f5f5;
    flex-grow: 1;
    padding: 0.625vw 0.625vw 0;
    height: calc(100% - 6.563vw);
    overflow: hidden;
  }
  &Captures {
    background-color: #f5f5f5;
    flex-grow: 1;
    padding: 0.625vw 0.625vw;
    height: calc(100% - 6.763vw);
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    pointer-events: none;
    &Active {
      pointer-events: all;
    }
  }
  &CapturesBody {
    background-color: #e5e5e5;
    border-radius: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1.25vw;
  }
  &AssetWrap {
    width: 100%;
    height: 100%;
    padding-bottom: 0.625vw;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    iframe {
      width: 100%;
      height: 100%;
      border-radius: 14px;
    }
  }
  &AssetContent {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: center;
    padding-top: 0.625vw;
    overflow-y: overlay;
    &::-webkit-scrollbar {
      display: none;
    }
    &Big {
      gap: 1vw 0.625vw;
      .assetcard {
        flex-basis: 24% !important;
        h2 {
          padding: 1.625vw 0.781vw 1.469vw;
        }
        &Dummy {
          flex-basis: 24% !important;
        }
      }
    }
  }
  .assetcard {
    padding: 0.625vw 0.625vw 0.781vw;
    border-radius: 8px;
    background: #e5e5e5;
    user-select: none;
    cursor: pointer;
    flex-basis: 19%;
    opacity: 0;
    &Dummy {
      flex-basis: 19%;
    }
    div {
      aspect-ratio: 16/9;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      border-radius: 4px;
    }
    &Active {
      background: #ff570f;
      h2 {
        color: #ffffff !important;
      }
      p {
        color: #ffffff !important;
      }
    }
    img {
      border-radius: 4px;
      max-width: 100%;
      width: 100%;
    }
    h2 {
      color: #808080;
      font-size: 1.094vw;
      font-weight: 400;
      line-height: normal;
      padding: 0.925vw 0.781vw 0.669vw;
      font-family: "Graphik Regular", sans-serif;
      letter-spacing: 0.1px;
      text-align: center;
    }
    p {
      color: #808080;
      font-size: 0.781vw;
      font-weight: 400;
      line-height: normal;
      padding: 0 0.781vw 0.313vw;
      font-family: "Graphik Regular", sans-serif;
      letter-spacing: 0.1px;
    }
  }
  &Pagination {
    padding: 0.59vw 0.98vw;
    background: #e5e5e5;
    border-radius: 12px;
    display: flex;
    gap: 0.625vw;
    justify-content: center;
    max-width: fit-content;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    p {
      color: #2a2a2a;
      padding: 4px;
      width: 34px;
      height: 34px;
      // font-size: 1.25vw;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      cursor: pointer;
      &.prev {
        margin-right: 0.698vw;
      }
      &.next {
        margin-left: 0.698vw;
      }
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &:hover {
        background-color: #bdbdbd;
      }
      &.active {
        background-color: #f3f4f4;
      }
    }
  }
  &PageFooter {
    position: relative;
    .unrealGridLayout {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding-right: 2vw;
      p {
        cursor: pointer;
        &.activeGrid {
          svg rect {
            opacity: 1 !important;
          }
        }
      }
      svg {
        rect {
          opacity: 1;
          &:nth-child(5) {
            opacity: 0.4;
          }
          &:nth-child(6) {
            opacity: 0.4;
          }
          &:nth-child(7) {
            opacity: 0.4;
          }
          &:nth-child(8) {
            opacity: 0.4;
          }
        }
      }
    }
  }

  &Footer {
    padding: 1.563vw 5.469vw 1.563vw 1.563vw;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 14px;
    margin: 0 0.625vw 0.625vw;
    position: relative;
    z-index: 1;
  }
  &InfoBtn {
    display: flex;
    align-items: center;
    gap: 4px;
    p {
      padding: 1.013vw 1.625vw;
      color: #939598;
      font-size: 0.8vw;
      line-height: normal;
      border-radius: 200px;
      transition: all 0.1s ease-in-out;
      // border: 1px solid #b5b5b5;
      cursor: pointer;
      letter-spacing: 0.6px;
      &:hover {
        background-color: #f3f4f4;
        // border-color: #4e4e4e;
      }
      &.active {
        color: #ff5515;
      }
    }
  }
  &Cta {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    button {
      padding: 1.016vw 1.563vw;
      border-radius: 999px;
      background: #2a2a2a;
      color: #e7e7e7;
      text-align: center;
      font-size: 1.094vw;
      font-weight: 400;
      line-height: 1;
      border: none;
      outline: none;
      // max-width: 200px;
      width: 100%;
      cursor: pointer;
      font-family: "Graphik Regular", sans-serif;
      letter-spacing: 1px;
      transition: all 0.1s ease-in-out;
      &:hover {
        background-color: #6e6e6e;
      }
      &.clicked {
        background-color: #ff5515;
      }
      &:disabled {
        background-color: #808080;
        cursor: not-allowed;
        &:hover {
          background-color: #808080;
        }
      }
    }
  }
  &Copy {
    label {
      color: #ff5515;
      font-size: 0.8vw;
      font-weight: 400;
      line-height: normal;
      display: inline-block;
    }
  }
  &Range {
    display: flex;
    gap: 1.563vw;
    align-items: center;
    justify-content: flex-end;
    &:first-child {
      padding-bottom: 4px;
    }
    p {
      color: #989898;
      text-align: right;
      font-size: 0.938vw;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
    }
    input[type="range"] {
      width: 9.375vw;
      -webkit-appearance: none;
      background: transparent;
    }

    input[type="range"]:focus {
      outline: none;
    }

    /* Chrome & Safari */
    input[type="range"]::-webkit-slider-runnable-track {
      background: #a9a9a9;
      border-radius: 999px;
      width: 100%;
      height: 0.313vw;
      cursor: pointer;
      opacity: 0.4;
    }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 0.781vw;
      width: 0.781vw;
      background: #fff;
      border-radius: 50%;
      border: 1px solid #000000;
      margin-top: -0.234vw;
      cursor: pointer;
    }

    /* Firefox */
    input[type="range"]::-moz-range-track {
      background: #a9a9a9;
      border-radius: 199px;
      width: 100%;
      height: 0.313vw;
      cursor: pointer;
      opacity: 0.4;
    }

    input[type="range"]::-moz-range-thumb {
      -webkit-appearance: none;
      height: 0.781vw;
      width: 0.781vw;
      background: #fff;
      border-radius: 50%;
      border: 1px solid #000000;
      margin-top: -0.234vw;
      cursor: pointer;
    }

    /* IE */
    input[type="range"]::-ms-track {
      background: #a9a9a9;
      border-radius: 199px;
      width: 100%;
      height: 0.313vw;
      cursor: pointer;
      opacity: 0.4;
    }

    input[type="range"]::-ms-thumb {
      -webkit-appearance: none;
      height: 0.781vw;
      width: 0.781vw;
      background: #fff;
      border-radius: 50%;
      border: 1px solid #000000;
      margin-top: -0.234vw;
      cursor: pointer;
    }
  }
  &Loader {
    height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: #ffffff;
    &Body {
      height: calc(100% - 8.063vw);
      display: flex;
      align-items: center;
      background-color: #f5f5f5;
      border-radius: 14px;
      margin: 0.625vw 0.625vw;
      justify-content: center;
    }
    .unrealFooter {
      .unrealLogo {
        padding-bottom: 0;
      }
      .unrealCta {
        margin-right: 140px;
        position: static;
        transform: none;
        button {
          pointer-events: none;
        }
      }
    }
    &Spin {
      width: 100px;
      height: 100px;
      border: 10px solid #ff5515;
      border-top: 10px solid #b73200;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
  }
  &LoaderSmall {
    width: 100%;
    height: 100%;
    &Body {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  .unsopported {
    display: none;
  }
  @media (max-width: 1280px) {
    &Sidebar {
      padding: 8px 5px 26px;
      // min-width: 200px;
      margin: 12px 0 12px 12px;
    }
    &Logo {
      padding-bottom: 4px;
      gap: 16px;
      svg {
        width: 60px;
        height: 16px;
      }
      p {
        font-size: 11px;
        padding-top: 4px;
      }
    }
    &List {
      gap: 5px;
    }
    &Qtn {
      padding: 10px 20px;
      span {
        img {
          width: 14px;
          height: 14px;
        }
      }
      h2 {
        font-size: 11px;
        padding-bottom: 4px;
      }
      h3 {
        font-size: 10px;
      }
    }
    &Opt {
      // max-width: 160px;
      p {
        padding: 6px 20px;
        font-size: 11px;
        &:first-child {
          margin-top: 4px;
        }
        &:not(:last-child) {
          margin-bottom: 2px;
        }
      }
    }
    &InfoBtn {
      gap: 20px;
      p {
        font-size: 12px;
        padding: 10px 20px;
      }
    }
    &Cta {
      button {
        font-size: 14px;
        padding: 12px 20px;
      }
    }
    &Captures {
      padding: 8px 8px;
      height: calc(100% - 90px);
    }
    &CapturesBody {
      padding-bottom: 16px;
    }
    &Footer {
      padding: 28px 58px 16px 20px;
    }

    &Copy {
      label {
        font-size: 12px;
      }
    }
    &Asset {
      padding: 8px 8px 0;
      height: calc(100% - 82px);
    }
    &AssetContent {
      padding-top: 8px;
      gap: 38px 8px;
    }
    .assetcard {
      padding: 8px 8px 12px;
      h2 {
        padding: 20px 10px 10px;
        font-size: 14px;
      }
      p {
        font-size: 10px;
        padding: 0 10px 4px;
      }
    }
    &Loader {
      &Body {
        height: calc(100% - 106px);
        margin: 8px;
      }
      .unrealFooter {
        margin: 0 8px 8px;
      }
    }
    &Pagination {
      padding: 12px 20px;
      gap: 8px;
      p {
        width: 34px;
        height: 34px;
        padding: 4px;
        &:first-child {
          margin-right: 12px;
        }
        &:last-child {
          margin-left: 12px;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .unsopported {
      display: flex;
      background-color: #f3f4f4;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100vh;
      p {
        color: #ff5515;
        font-size: 17px;
        line-height: 180%;
        text-align: center;
      }
    }
    .container {
      display: none;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
